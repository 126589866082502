'use client';
import Link from 'next/link';
import { FaArrowAltCircleRight } from 'react-icons/fa';

const TechnologySection = ({
  title = 'What Variety of Web Solutions Does',
  colouredTitle = 'Our Web Designing Company Offer?',
  description = 'What Variety of Web Solutions Does',
  items = [],
}) => {
  return (
    <section className="bg-white py-10 md:py-16 lg:py-20 w-full">
      <div className="max-w-screen-xl mx-auto text-center mb-6">
        <h3 className="text-black text-2xl">Technology Advantages</h3>
        <h2 className="font-extrabold text-black">
          {title}
          <span className="text-colorOrange-700">{colouredTitle}</span>
        </h2>
        <p
          className="text-black"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
      <div className="max-w-screen-2xl mx-7 lg:mx-auto md:flex md:flow-row items-center justify-center">
        <div className="flex-1 md:w-2/3">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {items.map((item, index) => {
              const [beforeColon, afterColon] = item.name.split(':');
              const bgColor =
                index % 2 === 0 ? 'bg-colorOrange-500' : 'bg-neutral-800';
              const textColor =
                index % 2 === 0 ? 'text-neutral-800' : 'text-colorOrange-400';
              return (
                <div
                  key={index}
                  className={`p-4 rounded-lg shadow-lg ${bgColor} min-h-[15rem] text-center flex items-center justify-center flex-col`}
                >
                  <div className={`w-12 h-12 mb-4 ${textColor}`}>
                    {item.icon}
                  </div>
                  <h4 className={`font-bold mb-2 ${textColor}`}>
                    {beforeColon}
                  </h4>
                  <p className={`text-base mb-4 ${textColor}`}>{afterColon}</p>
                  {item.link && (
                    <Link
                      href={item.link}
                      className={`text-sm uppercase font-medium ${textColor} hover:text-colorRed-500`}
                    >
                      Learn More
                      <FaArrowAltCircleRight
                        size={16}
                        className={`ml-2 inline ${textColor}`}
                      />
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologySection;
