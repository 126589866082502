'use client';
import Image from 'next/image';
import Link from 'next/link';

const PortfolioWebDesign = () => {
  return (
    <section className="py-12">
      <div className="max-w-screen-2xl mx-auto px-4 lg:px-0">
        <div className="text-center lg:text-left">
          <h2 className="font-extrabold text-gray-900">
            Website Design{' '}
            <span className="text-colorOrange-500">Portfolio</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-14 mt-8">
          {/* Portfolio Item 4 */}
          <div className="group overflow-hidden">
            <div className="relative">
              <Image
                src="/images/portfolio/bhatiagraphica/bhatia-2.jpg"
                alt="Bhatia Graphica"
                width={800}
                height={600}
                placeholder="blur"
                blurDataURL="/images/portfolio/bhatiagraphica/bhatia-2.jpg"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
              <Link
                href="/"
                className="absolute inset-0 bg-colorOrange-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end no-underline"
              >
                <div className="text-xl text-black group-hover:text-black p-6">
                  A visually engaging website for a printing services company.
                  Showcases artwork and pre-press capabilities. Designed for
                  easy navigation and functionality.
                </div>
              </Link>
            </div>
            <div className="mt-4 text-center text-gray-900 font-medium">
              Bhatia Graphica
            </div>
          </div>

          {/* Portfolio Item 5 */}
          <div className="group overflow-hidden">
            <div className="relative">
              <Image
                src="/images/portfolio/design-systems/design-2.jpg"
                alt="Design Systems"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                width={800}
                height={600}
                placeholder="blur"
                blurDataURL="/images/portfolio/design-systems/design-2.jpg"
              />
              <Link
                href="/"
                className="absolute inset-0 bg-colorOrange-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end no-underline"
              >
                <div className="text-xl text-black group-hover:text-black p-6">
                  A dynamic platform for showcasing modern architectural
                  projects. Focused on immersive visuals and creative layouts.
                  Built to leave a lasting impression on visitors.
                </div>
              </Link>
            </div>
            <div className="mt-4 text-center text-gray-900 font-medium">
              Design Systems
            </div>
          </div>

          {/* Portfolio Item 6 */}
          <div className="group overflow-hidden">
            <div className="relative">
              <Image
                src="/images/portfolio/fqhlaw/fqhlaw-hero.jpg"
                alt="Fqhlaw"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                width={800}
                height={600}
                placeholder="blur"
                blurDataURL="/images/portfolio/fqhlaw/fqhlaw-hero.jpg"
              />
              <Link
                href="/"
                className="absolute inset-0 bg-colorOrange-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end no-underline"
              >
                <div className="text-xl text-black group-hover:text-black p-6">
                  A comprehensive website for a law firm specializing in
                  immigration. Highlights services and legal expertise. Designed
                  for accessibility and clear communication.
                </div>
              </Link>
            </div>
            <div className="mt-4 text-center text-gray-900 font-medium">
              Fqhlaw
            </div>
          </div>

          {/* Portfolio Item 7 */}
          <div className="group overflow-hidden">
            <div className="relative">
              <Image
                src="/images/portfolio/matsze/matsze-hero.jpg"
                alt="Matsze"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                width={800}
                height={600}
                placeholder="blur"
                blurDataURL="/images/portfolio/matsze/matsze-hero.jpg"
              />
              <Link
                href="/"
                className="absolute inset-0 bg-colorOrange-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end no-underline"
              >
                <div className="text-xl text-black group-hover:text-black p-6">
                  A chic e-commerce platform for a modern fashion brand.
                  Features sleek visuals and easy-to-navigate categories. Built
                  to enhance customer shopping experiences.
                </div>
              </Link>
            </div>
            <div className="mt-4 text-center text-gray-900 font-medium">
              Matsze
            </div>
          </div>

          {/* Portfolio Item 8 */}
          <div className="group overflow-hidden">
            <div className="relative">
              <Image
                src="/images/portfolio/ntr/ntr-hero.jpg"
                alt="Ntr Security"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                width={800}
                height={600}
                placeholder="blur"
                blurDataURL="/images/portfolio/ntr/ntr-hero.jpg"
              />
              <Link
                href="/"
                className="absolute inset-0 bg-colorOrange-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end no-underline"
              >
                <div className="text-xl text-black group-hover:text-black p-6">
                  A professional website for a 24/7 security services provider.
                  Showcases expertise in corporate and residential security.
                  Built with trust and professionalism in mind.
                </div>
              </Link>
            </div>
            <div className="mt-4 text-center text-gray-900 font-medium">
              Ntr Security
            </div>
          </div>

          {/* Portfolio Item 9 */}
          <div className="group overflow-hidden">
            <div className="relative">
              <Image
                src="/images/portfolio/ojas/ojas-hero.jpg"
                alt="Ojas Shipping"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                width={800}
                height={600}
                placeholder="blur"
                blurDataURL="/images/portfolio/ojas/ojas-hero.jpg"
              />
              <Link
                href="/"
                className="absolute inset-0 bg-colorOrange-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end no-underline"
              >
                <div className="text-xl text-black group-hover:text-black p-6">
                  A vibrant and engaging website for a logistics company.
                  Highlights global solutions and streamlined services. Designed
                  for clarity and impactful storytelling.
                </div>
              </Link>
            </div>
            <div className="mt-4 text-center text-gray-900 font-medium">
              Ojas Shipping
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-20 mt-10">
          <Link
            href="/portfolio"
            className="bg-colorOrange-600 text-white py-2 px-10 rounded-md hover:bg-colorOrange-700 hover:text-white transition duration-300"
          >
            More Portfolio
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PortfolioWebDesign;
