"use client";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";

const CounterSection = ({
  title = "Our Results-Driven",
  colouredTitle = "Web Design and Marketing Campaigns",
  data = [
    {
      subtitle: "Performance-Driven",
      description:
        "Our websites not only look stunning but also deliver exceptional user experiences, high-speed performance, and superior SEO.",
      limit: "2000",
      imageSrc: "/images/icons/badge.png", // Add image URL
    },
    {
      subtitle: "Proven Results",
      description:
        "With a portfolio of successful projects, we have a track record of transforming businesses with bespoke web design that converts visitors into customers.",
      limit: "1500",
      imageSrc: "/images/icons/badge.png", // Add image URL
    },
  ],
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section className="relative bg-neutral-800 text-white py-16">
      <div className="max-w-screen-xl mx-auto">
      <h2 className="font-bold leading-tight text-white text-center">
          {title} <span className="text-colorOrange-500">{colouredTitle}</span>
        </h2>
      <div className=" px-6 lg:flex lg:items-center">
        {/* Left Section */}
        <div className="lg:w-2/5 text-center lg:text-left md:mr-10">
          <p className="mt-6 text-gray-300 text-2xl">
            Our team specializes in delivering measurable results for businesses
            like yours. See the impact of our tailored strategies that drive
            conversions and growth.
          </p>
        </div>
        <div className="lg:w-2/5">
          <Image src="/images/Google-Reviews.png" width={1000} height={500} alt="Google Reviews" className="w-72 px-6" />
        </div>
        {/* Slider Section */}
        <div className="lg:w-1/3 mt-10 lg:mt-0">
          <Slider {...settings}>
            {data.map((usp, index) => (
              <div
                key={index}
                className="relative bg-colorOrange-500 rounded-full flex items-center justify-between p-6 w-full max-w-xl mx-auto h-44"
              >
                {/* Background Overlay */}
                <div className="absolute inset-0 bg-colorOrange-700 opacity-30 rounded-full"></div>

                {/* Content */}
                <div className="relative z-10 flex items-center justify-between w-full px-6">
                  {/* Left Content */}
                  <div className="flex-1">
                    <div className="text-4xl md:text-7xl font-bold text-white p-0 m-0">
                      {usp.limit} 
                    </div>
                    <div className="text-white text-xl font-medium">
                      {usp.subtitle}
                    </div>
                  </div>
                  {/* Right Image */}
                  <div className="w-32 h-32 bg-colorOrange-600 rounded-full overflow-hidden">
                    <Image
                      width={100}
                      height={100}
                      src={usp.imageSrc}
                      alt={usp.subtitle}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      </div>
    </section>
  );
};

export default CounterSection;
