'use client';

import Image from 'next/image';
import Link from 'next/link';
import { fallbackImages } from '@/constants/imageConstants'; // Adjust the path as needed

const ImageBox = ({
  leftposition = true,
  title = 'Creative Web Design Solutions',
  colouredTitle = 'by Prateeksha',
  imageSrc = '',
  link = '',
  children,
}) => {
  const selectedImage =
    imageSrc ||
    fallbackImages[Math.floor(Math.random() * fallbackImages.length)];

  return (
    <section className="bg-white flex flex-col justify-center w-full">
      <div
        className={`flex flex-col md:flex-row items-center ${
          !leftposition ? 'md:flex-row-reverse' : ''
        }`}
      >
        {/* Image Section */}
        <div className="relative w-full md:w-1/2 h-64 md:h-[50rem]">
          <Image
            src={selectedImage}
            alt={`${title} ${colouredTitle}`}
            fill
            className="object-cover w-full h-full"
            quality={75}
            sizes="(max-width: 768px) 100vw, (min-width: 768px) 50vw"
          />
        </div>

        {/* Text Section */}
        <div
          className={`w-full md:w-1/2 px-6 md:px-12 py-8 ${
            leftposition ? 'text-left' : 'text-left md:pl-12'
          }`}
        >
          <h2 className="text-gray-800 mb-6">
            {title}{' '}
            <span className="text-colorOrange-600">{colouredTitle}</span>
          </h2>
          <div className="text-gray-600 text-base">{children}</div>
          {link && (
            <Link
              href={link}
              className="mt-6 inline-block bg-gray-800 text-white px-6 py-3 rounded-md hover:bg-gray-700"
            >
              Read More
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImageBox;
