import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/font/local/target.css?{\"path\":\"src/app/(services)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/SofiaProRegularAz.woff\",\"variable\":\"--font-sofiapro-regular-sans\",\"weight\":\"100 900\"}],\"variableName\":\"sofiaproRegular\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/font/local/target.css?{\"path\":\"src/app/(services)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/SofiaProRegularItalicAz.woff\",\"variable\":\"--font-sofiapro-regular-italics-sans\",\"weight\":\"100 900\"}],\"variableName\":\"sofiaproRegularItalics\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/font/local/target.css?{\"path\":\"src/app/(services)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/SofiaProBoldAz.woff\",\"variable\":\"--font-sofiapro-bold-sans\",\"weight\":\"100 900\"}],\"variableName\":\"sofiaproBold\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/node_modules/next/font/local/target.css?{\"path\":\"src/app/(services)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/SofiaProBoldItalicAz.woff\",\"variable\":\"--font-sofiapro-bold-italics-sans\",\"weight\":\"100 900\"}],\"variableName\":\"sofiaproBoldItalics\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateeksha.com/next.js/src/components/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateeksha.com/next.js/src/components/Header.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateeksha.com/next.js/src/styles/globals.scss");
