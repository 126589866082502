'use client';
import { Disclosure } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { AiFillPhone } from 'react-icons/ai';

const navigation = [
  {
    href: '/about-us',
    name: 'About',
    submenus: [
      {
        href: '/about-us',
        name: 'About Us',
      },
      {
        href: '/portfolio',
        name: 'Portfolio',
      },
    ],
  },
  {
    href: '/services',
    name: 'Packages',
    submenus: [
      { href: '/pay-monthly-websites', name: 'Pay Monthly Websites' },
      { href: '/monthly-seo-packages', name: 'Monthly SEO Packages' },
      {
        href: '/landing-pages/custom-website-design',
        name: 'Custom Web Design',
      },
      { href: '/shopify', name: 'Shopify Ecommerce Store' },
      { href: '/shopware', name: 'Shopware Store' },
      { href: '/laravel', name: 'Laravel Services' },
      { href: '/reactnative', name: 'Mobile App' },
      {
        href: '/news-portal-design-and-development',
        name: 'News Portal Design and Development',
      },
      { href: '/wordpress', name: 'WordPress' },
      { href: '/woocommerce', name: 'Woocommerce' },
    ],
  },
  {
    href: '/services',
    name: 'Services',
    submenus: [
      {
        href: '/webdesign',
        name: 'Website Design Services ',
      },
      {
        href: '/ecommerce',
        name: 'Ecommerce Web Design',
      },
      {
        href: '/website-maintenance',
        name: 'Website Maintenance Services',
      },
      {
        href: '/digital-marketing',
        name: 'SEO and Digital Marketing Services',
      },
      {
        href: '/web-development',
        name: 'Web Development',
      },
      {
        href: '/mobile-app-development-services',
        name: 'Mobile App Development',
      },
    ],
  },
  /* {
    href: "/industries",
    name: "Industries",
    submenus: [
      {
        href: "/industries/ecommerce-web-design-for-real-estate",
        name: "Real Estate",
      },
      { href: "/industries/biotech-website-design", name: "Biotech" },
      {
        href: "/industries/ecommerce-beauty-products",
        name: "Beauty Products",
      },
      {
        href: "/industries/financial-services-website",
        name: "Financial Services",
      },
      { href: "/industries/healthcare-website-design", name: "Healthcare" },
      {
        href: "/industries/home-services-website-design",
        name: "Home Services",
      },
      {
        href: "/industries/outdoor-website-design",
        name: "Outdoor Website",
      },
      {
        href: "/industries/restoration-website-design-services",
        name: "Restoration Services",
      },
      {
        href: "/industries/roofing-website-design",
        name: "Roofing Website Design",
      },
      {
        href: "/industries/web-design-for-family-lawyer",
        name: "Family Lawyer",
      },
      {
        href: "/industries/bankruptcy-attorney-web-design",
        name: "Bankruptcy Attorney",
      },
    ],
  }, */
  { href: '/blog', name: 'Blog' },
  { href: '/contact-us', name: 'Contact' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [openSubSubmenu, setOpenSubSubmenu] = useState(null); // Track second-level submenu

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
    setOpenSubSubmenu(null); // Close any open sub-submenu when toggling a submenu
  };

  const handleSubSubmenuToggle = (index) => {
    setOpenSubSubmenu(openSubSubmenu === index ? null : index);
  };

  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!event.target.closest('.submenu')) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="top-0 w-full bg-gray-700 text-white z-30">
      <div className="w-full bg-neutral-600 flex items-center justify-center py-1 text-base"></div>
      <Disclosure as="nav" className="bg-white uppercase font-semibold">
        {({ open, close }) => (
          <>
            <div className="max-w-screen-2xl mx-auto">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute2 inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 ml-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 w-64 mx-auto">
                  <Link href="/">
                    <Image
                      src="/images/logo-small.jpg"
                      alt="Prateeksha Web Design logo"
                      width={384}
                      height={72}
                      className="max-w-full h-auto"
                    />
                  </Link>
                </div>
                <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                  <div className="flex space-x-0">
                    {navigation.map((item, index) => (
                      <div key={item.name} className="relative submenu z-30">
                        {item.submenus ? (
                          <button
                            onClick={() => handleSubmenuToggle(index)}
                            className="text-black px-3 py-2 rounded-md text-base font-medium flex items-center no-underline uppercase"
                          >
                            {item.name}
                            {item.submenus && (
                              <ChevronDownIcon
                                className={`ml-1 h-4 w-4 transition-transform ${
                                  openSubmenu === index
                                    ? 'transform rotate-180'
                                    : ''
                                }`}
                              />
                            )}
                          </button>
                        ) : (
                          <Link
                            href={item.href}
                            className="text-black px-3 py-2 rounded-md text-base font-medium flex items-center uppercase no-underline "
                          >
                            {item.name}
                          </Link>
                        )}
                        {openSubmenu === index && item.submenus && (
                          <div className="absolute bg-neutral-200 shadow-lg rounded-md py-2 mt-1 w-72">
                            {item.submenus.map((subitem, subIndex) => (
                              <div key={subitem.name} className="relative">
                                {subitem.submenus ? (
                                  <>
                                    <button
                                      onClick={() =>
                                        handleSubSubmenuToggle(subIndex)
                                      }
                                      className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 flex items-center	 justify-start w-full text-left"
                                    >
                                      {subitem.name}
                                      <ChevronDownIcon
                                        className={`ml-2 h-4 w-4 transition-transform ${
                                          openSubSubmenu === subIndex
                                            ? 'transform rotate-180'
                                            : ''
                                        }`}
                                      />
                                    </button>
                                    {openSubSubmenu === subIndex && (
                                      <div className="absolute left-full top-0 mt-1 bg-neutral-200 shadow-lg rounded-md py-2 w-72">
                                        {subitem.submenus.map((subSubItem) => (
                                          <Link
                                            key={subSubItem.name}
                                            href={subSubItem.href}
                                            className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 no-underline uppercase"
                                          >
                                            {subSubItem.name}
                                          </Link>
                                        ))}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 no-underline uppercase"
                                  >
                                    {subitem.name}
                                  </Link>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pl-6 hidden md:block">
                  <div className="flex items-center space-x-3 ">
                    <Link href="/request-a-quote">
                      <button className="hover:bg-neutral-600 bg-colorOrange-500 hover:text-colorOrange-200 text-white transition py-4 px-10 rounded-full text-base font-bold uppercase">
                        Start your Project
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <div key={item.name}>
                    {item.submenus ? (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            {/* Main Menu Button */}
                            <Disclosure.Button
                              as="button"
                              className="text-black px-3 py-2 rounded-md text-base font-medium w-full flex items-center justify-between no-underline uppercase  "
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`h-5 w-5 transition-transform ${
                                  open ? 'rotate-180' : ''
                                }`}
                              />
                            </Disclosure.Button>

                            {/* Submenu */}
                            <Disclosure.Panel className="pl-5 space-y-1">
                              {item.submenus.map((subitem) => (
                                <div key={subitem.name}>
                                  {subitem.submenus ? (
                                    <Disclosure>
                                      {({ open: subOpen }) => (
                                        <>
                                          {/* Submenu Button */}
                                          <Disclosure.Button
                                            as="button"
                                            className="text-gray-700 px-3 py-2 rounded-md text-base font-medium w-full flex items-center justify-between"
                                          >
                                            {subitem.name}
                                            <ChevronDownIcon
                                              className={`h-5 w-5 transition-transform ${
                                                subOpen ? 'rotate-180' : ''
                                              }`}
                                            />
                                          </Disclosure.Button>

                                          {/* Sub-Submenu */}
                                          <Disclosure.Panel className="pl-5 space-y-1">
                                            {subitem.submenus.map(
                                              (subSubItem) => (
                                                <Link
                                                  key={subSubItem.name}
                                                  href={subSubItem.href}
                                                  className="block px-3 py-2 text-gray-600 hover:bg-gray-100 rounded-md no-underline uppercase"
                                                >
                                                  {subSubItem.name}
                                                </Link>
                                              )
                                            )}
                                          </Disclosure.Panel>
                                        </>
                                      )}
                                    </Disclosure>
                                  ) : (
                                    <Link
                                      href={subitem.href}
                                      className="block px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md no-underline uppercase"
                                    >
                                      {subitem.name}
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <Link
                        href={item.href}
                        className="block text-black px-3 py-2 rounded-md text-base font-medium no-underline uppercase"
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
